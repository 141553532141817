/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import client from "../client";


export function LUTs() {
  
  const [pack, setLUTs] = useState(null);
  const [value, setValue] = useState('');
  const [toggle, setToggle] = useState(true);
  const [password, setPassword] = useState('null');
  const [error, setError] = useState('');  

  useEffect(() => {
    client
      .fetch(`*[slug.current == 'nduval'] |  order(_createdAt desc) `)
      .then((data) => setLUTs(data))
      .catch(console.error);
  }, []);

  function submit(pass){
    if (pass !== '') {
      setPassword(pass.target.value)
      if ('console' === pass.target.value) {
        setToggle(false)
        setValue('console')
      } else {
        setError("Wrong password")
      }
    } else {
      setError("Please enter password")
    }
  }

  return (
    <div>
      { toggle ? 
        <div className="lut-password">
        <div className="password-panel">
          <h2>Nicolas Duval</h2>
          <p style={{fontSize:12}}>Tools downloads</p>
          <p style={{fontSize:12, color:'#dd5b5b'}}>{error}</p>
          <input type="password" onChange={setValue} autoFocus placeholder="Password" />
          <br/>
          <button onClick={() => submit(value)}>Submit</button>
        </div>
      </div>
      : null }
     { pack && pack.password === password ?
        <div className="lut-package">
          <h2>{ (pack.title) }</h2>
            <div>
              { pack.package_name ?
                pack.package_name.map((pname, i) => (
                  <div key={i}>
                    Downalod : <a href={pack.package_url[i]} download>LUT Pacakge {pname}</a>
                  </div>  
              ))
            : null}
            </div>
        </div>
      : null }
    </div>
  );
}
