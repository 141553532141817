import React from "react";
// import { Menu } from "./menu.js";
import { Route, Routes } from "react-router-dom";

import { Home } from "./compo/home";
import { Project } from "./compo/project";
import { Media } from "./compo/media";
import { Contact } from "./compo/contact";
import { LUTs } from "./compo/luts";
import { Packages } from "./compo/packages";

import "./App.css";

export default function App() {
  return (
    <div className="main-container">
      
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/features" element={<Media type={"Feature film"}/>}></Route>
        <Route path="/short" element={<Media type={"short film"}/>}></Route>
        <Route path="/tv-vod" element={<Media type={"tv-vod"}/>}></Route>
        <Route path="/docs" element={<Media type={"Documentary"} />}></Route>
        <Route path="/other" element={<Media type={"other"} />}></Route>
        {/* <Route path="/ads" element={<Media type={"ad"} />}></Route>
        <Route path="/music" element={<Media type={"music video"} />}></Route> */}
        <Route path="/:id" element={<Project />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/luts" element={<LUTs />}></Route>
        <Route path="/luts/:id" element={<Packages />}></Route>
      </Routes>
    </div>
  );
}
