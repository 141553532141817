/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import client from "../client";
import { Menu } from "../menu";

export function Packages() {
  
  const { id } = useParams();
  const [pack, setPackage] = useState('');
  const [value, setValue] = useState('');
  const [toggle, setToggle] = useState(true);
  const [password, setPassword] = useState('null');
  const [error, setError] = useState('');

  useEffect(() => {
    client
      .fetch(`*[slug.current == '${id}']`)
      .then((data) => setPackage(data[0]))
      .catch(console.error);
  }, []);


  function submit(pass){
    if (pass !== '') {
      setPassword(pass.target.value)
      if (pack.password === pass.target.value) {
        setToggle(false)
      } else {
        setError("Wrong password")
      }
    } else {
      setError("Please enter password")
    }
  }
  
  return (
    <div className="LUT">
      <Menu />
      { toggle ? 
        <div className="lut-password">
          <div className="password-panel">
            <h2>{ (pack.title) }</h2>
            <p style={{fontSize:12}}>LUTs Pacakges</p>
            <p style={{fontSize:12, color:'#dd5b5b'}}>{error}</p>
            <input type="password" onChange={setValue} autoFocus placeholder="Password" />
            <br/>
            <button onClick={() => submit(value)}>Submit</button>
          </div>
        </div>
      : null }

      
      { pack && pack.password === password ?
        <div className="lut-package">
            <h2>{ (pack.title) }</h2>
            <div>
              { pack.package_name ?
                pack.package_name.map((pname, i) => (    
                  <div key={i}>
                    <a className="lut-button" href={pack.package_url[i]} download>
                    <div className="lut-package-element">
                      Download LUT Pacakge 
                      <span className="lut-version">{pname}</span>
                      <p>
                        {
                          pack.content[i].split(', ').map((item, e) => (<p key={e} className="lut-content-list">{item}</p>))
                        }
                      </p>

                      {/* <p className="lut-content">date {pack.date[i]}</p> */}
                    </div>
                    </a>
                  </div>  
              ))
              : null}
            </div>
            <span className="lut-email">+32 491 367 404 | </span>
            <span className="lut-email"><a href="mailto:mail@nduval.com">mail@nduval.com</a></span>
        </div>
      : null }
    </div>
  );
}

