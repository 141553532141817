/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Menu } from "../menu";
export function Contact() {
  return (
    <div>
      <Menu />
      <div className="project-container">
        <div className="project-descrition">
          <div className="project-descrition-text">
            <ul className="project-descrition-list">
              <li>
                <a style={{"padding":"0px"}} className="link" href="mailto:mail@nduval.com" >mail@nduval.com</a>
              </li>
              <li><a style={{"padding":"0px"}} className="link" href="tel:+32 491 367 404" >+32 491 367 404</a></li>
              <li><br></br></li>
              <li>I'm born in France in 1985, I speak both <span style={{color:'#2196F3'}}>French</span> and <span style={{color:'#2196F3'}}>English</span>.</li>
              <li>I work on both <span style={{color:'#2196F3'}}>Baselight</span> and <span style={{color:'#2196F3'}}>DaVinci Resolve</span>.</li>
              <li><br></br></li>
              <li>I'm <span style={{color:'#2196F3'}}>based in Brussels</span></li>
              <li>Colour grading suite available in the south of Brussels</li>

              <li><br></br></li>
              <li>
                <a style={{"padding":"0px"}} className="link" href="https://www.imdb.com/name/nm4556574/?ref_=ttfc_fc_cr" target="blank">imdb</a> / 
                <a className="link" href="https://www.instagram.com/n_duval/" target="blank">insta</a>
              </li>

            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
