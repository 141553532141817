/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { IoMdMenu } from "react-icons/io";


export function Menu() {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    if (isMenuOpen) {
      setMenuOpen(false)
    } else {
      setMenuOpen(true)
    }
  }

  return (
    <div className="app-header">

      <div className="menu-slide" onClick={()=>toggleMenu()} >
        <IoMdMenu />
      </div>
    
      <div className="header">
        <a className="" style={{color:'white', textDecoration:'none'}} href="/">NICOLAS DUVAL</a>
        <p className="sub-title">
          <a style={{color:'white', textDecoration:'none'}} href="https://nduval.quickconnect.to/sharing/OFvpdeXio">DIGITAL </a>
          <a style={{color:'white', textDecoration:'none'}} href="https://gofile.me/7ug21/IegAlqFZl">COLOURIST</a>
        </p>
      </div>

      {(isMenuOpen) ?           
        <div className="menu-phone">
          <ul>
            <li><a className="link" href="/">all</a></li>
            <li><a className="link" href="/features">feature films</a></li>
            <li><a className="link" href="/short">short films</a></li>
            <li><a className="link" href="/tv-vod">tv-vod</a></li>
            <li><a className="link" href="/docs">documentaries</a></li>
            <li><a className="link" href="/other">other</a></li>
            <li><a className="link" href="/contact">contact</a></li>
          </ul>
        </div>
      : null }

        <div className="menu">
          <ul>
            <li><a className="link" href="/">all</a></li>
            <li><a className="link" href="/features">feature films</a></li>
            <li><a className="link" href="/short">short films</a></li>
            <li><a className="link" href="/tv-vod">tv-vod</a></li>
            <li><a className="link" href="/docs">documentaries</a></li>
            <li><a className="link" href="/other">other</a></li>
            <li><a className="link" href="/contact">contact</a></li>
          </ul>
        </div> 
    </div>
  );
}
